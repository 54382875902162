<template>
  <v-text-field
    v-bind="$attrs"
    v-mask="long ? phoneMaskLong : phoneMask"
    v-on="{ ...$listeners, input: (event) => handleInput }"
    :placeholder="long ? '(4XX)-000-00-00' : '000-00-00'"
    :value="value"
    @input="handleInput"
  >
    <template v-slot:append>
      <v-progress-circular
        v-if="loading"
        size="20"
        color="info"
        indeterminate
      ></v-progress-circular>
    </template>
  </v-text-field>
</template>

<script>
import { mask } from "vue-the-mask";
import { unmask } from "@/utils/mask";

export default {
  directives: { mask },
  props: {
    value: {
      type: String,
      default: "",
    },
    long: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      number: "",
      phoneMaskLong: "(###)-###-##-##",
      phoneMask: "###-##-##",
    };
  },
  methods: {
    handleInput(value) {
      this.number = unmask(value, this.phoneMaskLong);
      this.$emit("input", unmask(value, this.phoneMaskLong));
    },
  },
};
</script>
