<template >
  <div class="contact-container" v-scroll="onScroll" id="contact">
    <NavBar :flat="flat" />
    <div
      class="form-all-container d-flex flex-column"
      style="min-height: 100vh"
    >
      <div class="form-container-top-half"></div>
      <div class="form-container-bottom-half"></div>
      <div class="content">
        <v-container class="pt-16 pb-16">
          <v-row class="d-flex flex-column flex-lg-row align-start">
            <v-col cols="12" lg="6"
              ><v-row>
                <v-col cols="12" class="contactanos-title">Contáctanos</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="contactanos-copy">
                  <br />
                  <p>
                    Para
                    <b
                      >nosotros es un placer ser tu mejor aliado de movilidad y
                      encargarnos de la comodidad de tus ejecutivos</b
                    >
                    asegurando que estarán ahí cuando los necesites y que
                    recibirán el traslado más exclusivo y seguro de Venezuela.
                  </p>
                  <br />
                  <p>
                    Para poder ayudarte, necesitamos que nos proveas la
                    siguiente información para que nuestro equipo se comunique
                    contigo.
                  </p>
                </v-col>
              </v-row>
              <div class="mt-16" v-if="$vuetify.breakpoint.lgAndUp">
                <v-row>
                  <v-col class="contactanos-title2">
                    Creado a la medida para tu empresa
                  </v-col>
                </v-row>
                <v-row
                  dense
                  class="ma-0 pa-0 mt-8 d-flex align-center"
                  v-for="bullet in contactaBullets"
                  :key="bullet.index"
                >
                  <v-col class="d-flex justify-center align-center" cols="2">
                    <v-img
                      :src="bullet.icon"
                      max-height="40"
                      max-width="40"
                    ></v-img>
                  </v-col>
                  <v-col class="d-flex flex-column" cols="10">
                    <div class="contactanos-bullet-title">
                      {{ bullet.title }}
                    </div>
                    <div class="contactanos-bullet-copy">
                      {{ bullet.copy }}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" lg="6" class="d-flex flex-column"
              ><div class="form-container d-flex flex-column py-16 px-8">
                <v-row dense class="ma-0 pa-0">
                  <v-col cols="12" class="form-title ml-n1">
                    Datos básicos del contacto
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="form-subtitle">
                    Llena este formulario para concretar una reunión con
                    nosotros
                  </v-col>
                </v-row>
                <ValidationObserver v-slot="{ invalid }">
                  <v-form class="mt-10" @submit.prevent="handleSubmit">
                    <!-- nombre y apellido -->
                    <v-row dense>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="toLowerCase('Nombre')"
                          rules="required|min:2|max:150|alpha_spaces"
                          v-slot="{ errors }"
                          :debounce="450"
                          slim
                        >
                          <v-text-field
                            v-model="form.name"
                            label="Nombre"
                            outlined
                            :disabled="loading"
                            :error-messages="errors[0]"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <ValidationProvider
                          :name="toLowerCase('Apellido')"
                          rules="required|min:2|max:150|alpha_spaces"
                          v-slot="{ errors }"
                          :debounce="450"
                          slim
                        >
                          <v-text-field
                            v-model="form.lastName"
                            label="Apellido"
                            outlined
                            :disabled="loading"
                            :error-messages="errors[0]"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <ValidationProvider
                          :name="toLowerCase('Operadora')"
                          rules="required"
                          v-slot="{ errors }"
                          :debounce="450"
                          slim
                        >
                          <v-select
                            v-model="form.phonePrefix"
                            :items="form.phonePrefixList"
                            outlined
                            label="Operadora"
                            :disabled="loading"
                            :error-messages="errors[0]"
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="8">
                        <ValidationProvider
                          :name="toLowerCase('Número de teléfono')"
                          rules="required|min:7|max:7"
                          v-slot="{ errors }"
                          slim
                        >
                          <PhoneInput
                            :label="'Teléfono de contacto'"
                            v-model="form.phone"
                            outlined
                            :long="false"
                            type="tel"
                            :error-messages="errors[0]"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="toLowerCase('Correo electrónico')"
                          rules="required|email"
                          v-slot="{ errors }"
                          slim
                          :debounce="450"
                        >
                          <v-text-field
                            v-model="form.email"
                            outlined
                            label="Correo electrónico"
                            :error-messages="errors[0]"
                            :disabled="loading"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="toLowerCase('Posición')"
                          rules="required|min:2|max:150"
                          v-slot="{ errors }"
                          :debounce="450"
                          slim
                        >
                          <v-text-field
                            v-model="form.position"
                            label="Posición"
                            outlined
                            :error-messages="errors[0]"
                            :disbled="loading"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="toLowerCase('Razón social de la empresa')"
                          rules="required|min:2|max:150"
                          v-slot="{ errors }"
                          :debounce="450"
                          slim
                        >
                          <v-text-field
                            v-model="form.businessName"
                            label="Razón social de la empresa"
                            outlined
                            :error-messages="errors[0]"
                            :disabled="loading"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <ValidationProvider
                          :name="toLowerCase('Tipo de rif')"
                          rules="required"
                          v-slot="{ errors }"
                          slim
                        >
                          <v-select
                            :items="form.rifPrefixList"
                            :label="'Tipo de rif'"
                            outlined
                            v-model="form.rifPrefix"
                            :error-messages="errors[0]"
                            :disbaled="loading"
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="8">
                        <ValidationProvider
                          :name="toLowerCase('Número de rif')"
                          rules="required|min:6|max:11|numeric"
                          v-slot="{ errors }"
                          slim
                        >
                          <v-text-field
                            v-model="form.rif"
                            outlined
                            label="Número de rif"
                            type="tel"
                            :error-messages="errors[0]"
                            :disabled="loading"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          name="tamaño de la empresa"
                          rules="required"
                          v-slot="{ errors }"
                          slim
                        >
                          <v-select
                            label="Tamaño de la empresa"
                            hint="¿Cuál es el tamaño de la empresa en número de empleados?"
                            persistent-hint
                            outlined
                            :items="form.businessEmployeeSizeList"
                            v-model="form.businessEmployeeSize"
                            :error-messages="errors[0]"
                            :disabled="loading"
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          name="traslados a la semana"
                          rules="required"
                          v-slot="{ errors }"
                          slim
                        >
                          <v-select
                            outlined
                            label="Traslados a la semana"
                            placeholder="¿Cuántos traslados necesitaría su empresa en promedio a la semana?"
                            hint="¿Cuántos traslados necesitaría su empresa en promedio a la semana?"
                            persistent-hint
                            :items="form.businessTripsWeekList"
                            v-model="form.businessTripsWeek"
                            :error-messages="errors[0]"
                            :disabled="loading"
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          name="ciudad"
                          rules="required"
                          v-slot="{ errors }"
                          slim
                        >
                          <v-select
                            outlined
                            label="Ciudad"
                            hint="¿En qué ciudad se encuentra la población de empleados que deseas movilizar?"
                            persistent-hint
                            :items="form.businessCityList"
                            v-model="form.businessCity"
                            :error-messages="errors[0]"
                            :disabled="loading"
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <ValidationProvider
                          name="mensaje"
                          rules="required"
                          v-slot="{ errors }"
                          slim
                        >
                          <v-textarea
                            outlined
                            label="Mensaje"
                            hint="Nos gustaría conocer un poco más sobre su empresa. "
                            persistent-hint
                            v-model="form.businessAbout"
                            :error-messages="errors[0]"
                            :disabled="loading"
                          >
                          </v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense class="mt-8">
                      <v-col
                        cols="12"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <v-btn
                          color="primary"
                          type="submit"
                          class="hero-button"
                          width="200"
                          min-height="50"
                          :loading="loading"
                          :disabled="invalid || loading"
                        >
                          Enviar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </div>
              <div class="mt-16" v-if="!$vuetify.breakpoint.lgAndUp">
                <v-row>
                  <v-col class="contactanos-title2">
                    Creado a la medida para tu empresa
                  </v-col>
                </v-row>
                <v-row
                  dense
                  class="ma-0 pa-0 mt-8 d-flex align-center"
                  v-for="bullet in contactaBullets"
                  :key="bullet.index"
                >
                  <v-col class="d-flex justify-center align-center" cols="2">
                    <v-img
                      :src="bullet.icon"
                      max-height="40"
                      max-width="40"
                    ></v-img>
                  </v-col>
                  <v-col class="d-flex flex-column" cols="10">
                    <div class="contactanos-bullet-title">
                      {{ bullet.title }}
                    </div>
                    <div class="contactanos-bullet-copy">
                      {{ bullet.copy }}
                    </div>
                  </v-col>
                </v-row>
              </div></v-col
            >
          </v-row>
        </v-container>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { setVeeLocale } from "@/utils";
import { loadLanguageAsync } from "@/plugins/i18n";

import { capitalizeFirstLetter, toLowerCase } from "@/utils";

import { mask } from "vue-the-mask";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import PhoneInput from "@/components/PhoneInput";

import axios from "@/plugins/axios";

export default {
  name: "Contact",
  components: {
    NavBar,
    Footer,
    PhoneInput,
  },
  data() {
    return {
      flat: false,
      color: "",
      fab: false,
      contactaBullets: [
        {
          title: "Conductores certificados en cada viaje",
          copy: "Certificamos a todos nuestros conductores para garantizar la máxima seguridad en los traslados de tu empresa.",
          icon: require("./assets/cards/wheel.svg"),
        },
        {
          title: "Disponibilidad inmediata",
          copy: "Desde motos hasta vans de pasajeros. Accede a más de 20.000 conductores disponibles para llevarte a dónde tu operación lo requiera.",
          icon: require("./assets/cards/clock.svg"),
        },
        {
          title: "Acompañamiento personalizado",
          copy: "Nuestro equipo hace seguimiento en tiempo real para atender las necesidades de tu empresa en cualquier momento.",
          icon: require("./assets/cards/monitor.svg"),
        },
      ],
      loading: false,
      form: {
        email: "",
        businessName: "",
        phonePrefixList: ["0412", "0414", "0424", "0416", "0426"],
        phonePrefix: "",
        phone: "",
        rifPrefixList: ["V", "E", "J", "G", "P", "C"],
        rifPrefix: "",
        rif: "",
        name: "",
        lastName: "",
        fullName: "",
        position: "",
        businessEmployeeSizeList: [
          "1-10",
          "10-50",
          "50-100",
          "100-200",
          "200-1000",
          "+1000",
        ],
        businessEmployeeSize: "",
        businessTripsWeekList: ["1-5", "5-10", "10-20", "20-50", "+50"],
        businessTripsWeek: "",
        businessCityList: [
          "Altos Mirandinos",
          "Barcelona - Puerto La Cruz - Lechería",
          "Barquisimeto",
          "Caracas",
          "Guarenas-Guatire",
          "Maracaibo",
          "Maracay",
          "Puerto Ordaz",
          "Valencia",
          "Otro (indique)",
        ],
        businessCity: "",
        otherBusinessCity: "",
        businessAbout: "",
      },
    };
  },
  methods: {
    capitalizeFirstLetter,
    toLowerCase,
    ...mapActions("auth", ["setLang"]),

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.scrollY || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    async handleSubmit() {
      // if (this.businessCity === "Otro (indique)") {
      //   this.businessCity = this.otherBusinessCity;
      // }
      // this.step = 3;
      try {
        this.loading = true;
        const {
          businessAbout,
          businessCity,
          businessTripsWeek,
          businessEmployeeSize,
          position,
          name,
          lastName,
          businessName,
          email,
        } = this.form;
        const params = {
          description: businessAbout,
          city_name: businessCity,
          avg_trip_estimated: businessTripsWeek,
          employees_estimated: businessEmployeeSize,
          contact_position: position,
          contact_name: name + " " + lastName,
          phone: this.computedContactPhone,
          country_phone_code: "+58",
          rif: this.computedContactRif,
          name: businessName,
          email: email,
        };
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_URL}/register_prospect`,
          params
        );
        if (!!data.success) {
          this.$dialog.notify.success(
            "Gracias por tu interés. Nuestro equipo de ventas se pondrá en contacto contigo"
          );
          await new Promise((r) => setTimeout(r, 3000));
          this.$router.push({ name: "landing" });
        } else {
          throw new Error("Error enviando formulario");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    computedContactPhone() {
      let value = this.form.phonePrefix + this.form.phone;
      value = value.slice(1);
      return value;
    },
    computedContactRif() {
      let value = this.form.rifPrefix + "-" + this.form.rif;
      return value;
    },
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "#F5F6F7";
        this.flat = false;
      } else {
        this.color = "#F5F6F7";
        this.flat = true;
      }
    },
    "form.businessName": function (newVal) {
      this.form.businessName = this.capitalizeFirstLetter(newVal);
    },
    "form.name": function (newVal) {
      this.form.name = this.capitalizeFirstLetter(newVal);
    },
    "form.lastName": function (newVal) {
      this.form.lastName = this.capitalizeFirstLetter(newVal);
    },
    "form.position": function (newVal) {
      this.form.position = this.capitalizeFirstLetter(newVal);
    },
    "form.otherBusinessCity": function (newVal) {
      this.form.otherBusinessCity = this.capitalizeFirstLetter(newVal);
    },
    "form.businessAbout": function (newVal) {
      this.form.businessAbout = this.capitalizeFirstLetter(newVal);
    },
  },
  created() {
    const top = window.scrollY || 0;
    if (top <= 60) {
      this.color = "#F5F6F7";
      this.flat = true;
    }
    this.$vuetify.theme.dark = false;

    //locale en es
    const locale = "es";
    this.setLang(locale);
    setVeeLocale(locale);
    this.$vuetify.lang.current = locale;
    loadLanguageAsync(locale);
  },
};
</script>
<style lang="scss" scoped>
.contact-container {
  background-color: #ffffff;
  overflow: hidden;
}

.form-all-container {
  // min-height: 100vh;
  // height: auto;
  background-color: #f0f0f0;
  // position: relative;
  font-family: Urbanist;

  // &-top-half {
  //   background: white;
  //   min-height: 50vh;
  // }
  // &-bottom-half {
  //   background: lightgray;
  //   min-height: 50vh;
  // }
}
.content {
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 2;
  // min-width: 100vw;
}

.contactanos {
  &-title {
    color: #31d3ae;
    font-family: Urbanist;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: 62px; /* 103.333% */
    text-align: justify;
  }
  &-copy {
    color: #000421;
    text-align: justify;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 145.833% */
    text-align: justify;
  }
  &-title2 {
    color: #000421;
    font-family: Urbanist;
    font-size: 38.211px;
    font-style: normal;
    font-weight: 800;
    line-height: 39.485px; /* 103.333% */
  }
  &-bullet {
    &-title {
      color: #000421;
      font-family: Urbanist;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &-copy {
      color: #000421;
      font-family: Urbanist;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26.5px; /* 147.222% */
    }
  }
}

.form {
  &-container {
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  }
  &-title {
    color: #000421;
    font-family: Urbanist;
    font-size: 24.942px;
    font-style: normal;
    font-weight: 800;
    line-height: 25.773px; /* 103.333% */
  }
  &-subtitle {
    color: #000421;
    text-align: justify;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.hero-button {
  text-transform: none !important;
  color: #f5f6f7;
  font-family: Urbanist;
  font-size: 25.776px;
  font-style: normal;
  font-weight: 800;
  line-height: 34.368px;
  border-radius: 11px;
}

@media (max-width: 960px) {
  .contactanos {
    &-title {
      color: #31d3ae;
      font-family: Urbanist;
      font-size: 42.874px;
      font-style: normal;
      font-weight: 800;
      line-height: 44.303px; /* 103.333% */
    }
    &-copy {
      color: #000421;
      text-align: justify;
      font-family: Urbanist;
      font-size: 17.15px;
      font-style: normal;
      font-weight: 500;
      line-height: 25.01px; /* 145.833% */
    }
    &-title2 {
      color: #000421;
      font-family: Urbanist;
      font-size: 31.691px;
      font-style: normal;
      font-weight: 800;
      line-height: 32.748px; /* 103.333% */
    }
    &-bullet {
      &-title {
        color: #000421;
        font-family: Urbanist;
        font-size: 14.929px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &-copy {
        color: #000421;
        font-family: Urbanist;
        font-size: 14.929px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.978px; /* 147.222% */
      }
    }
  }
}
</style>